/* eslint-disable react/destructuring-assignment */
import Link from 'next/link'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import FunctionalButton from './FunctionalButton'

const NavigationalButton = ({
  children,
  variant,
  onClick,
  className,
  widthType,
  href,
  ...props
}) => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const buttonProps = {
    ...props,
    variant,
    onClick,
    className,
    widthType,
  }

  const content = (
    <FunctionalButton {...buttonProps}>{children}</FunctionalButton>
  )

  if (!isClient) {
    // Server-side rendering
    return (
      <Link href={href} passHref>
        {content}
      </Link>
    )
  }

  // Client-side rendering
  return content
}

export default NavigationalButton

NavigationalButton.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined']),
  widthType: PropTypes.oneOf(['max-content', 'full']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string.isRequired,
}

NavigationalButton.defaultProps = {
  variant: 'contained',
  widthType: 'max-content',
  className: '',
  onClick: undefined,
}
