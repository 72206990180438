/* eslint-disable react/destructuring-assignment */
import { Button } from 'antd'
import cn from 'classname'
import PropTypes from 'prop-types'

const FunctionalButton = ({
  children,
  variant,
  onClick,
  className,
  widthType,
  ...props
}) => (
  <Button
    onClick={onClick}
    className={cn(
      'font-sfui px-4 py-3 h-12 rounded-lg font-semibold',
      {
        'border-none bg-g400 text-white': variant === 'contained',
        'border border-n75 text-n800': variant === 'outlined',
      },
      widthType === 'max-content' ? 'w-max' : 'w-full',
      className,
    )}
    {...props}
  >
    {children}
  </Button>
)

export default FunctionalButton

FunctionalButton.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined']),
  widthType: PropTypes.oneOf(['max-content', 'full']),
  className: PropTypes.string,
  onClick: PropTypes.func,
}

FunctionalButton.defaultProps = {
  variant: 'contained',
  widthType: 'max-content',
  className: '',
  onClick: undefined,
}
